import { Link } from "react-router-dom";

const AllDemosLinks = () => {
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">All Demos Links</h1>
      <ul className="space-y-4">
        <li>
          <Link
            to="/basicplan"
            className="block text-lg font-medium text-blue-600 hover:text-blue-800 hover:underline"
          >
            Basic Plan
          </Link>
        </li>
        <li>
          <Link
            to="/quickaccesslinks"
            className="block text-lg font-medium text-blue-600 hover:text-blue-800 hover:underline"
          >
            Quick Access Links
          </Link>
        </li>
        <li>
          <Link
            to="/maplocationandphoto"
            className="block text-lg font-medium text-blue-600 hover:text-blue-800 hover:underline"
          >
            Map Location and Photo
          </Link>
        </li>
        <li>
          <Link
            to="/popularprofile"
            className="block text-lg font-medium text-blue-600 hover:text-blue-800 hover:underline"
          >
            Popular Profile
          </Link>
        </li>
        <li>
          <Link
            to="/eventspotlight"
            className="block text-lg font-medium text-blue-600 hover:text-blue-800 hover:underline"
          >
            Event Spotlight
          </Link>
        </li>
        <li>
          <Link
            to="/enquiryform"
            className="block text-lg font-medium text-blue-600 hover:text-blue-800 hover:underline"
          >
            Enquiry Form
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AllDemosLinks;
