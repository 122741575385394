import React, { useState } from "react";
import { FaUser, FaImages } from "react-icons/fa";

const PopularProfile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const openModal = (imageSrc) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-br from-teal-400 via-blue-500 to-purple-600 animate-gradient-move">
      <div className="bg-white shadow-2xl rounded-xl p-6 w-full max-w-2xl transform transition-transform duration-300 ease-in-out">
        {/* Profile Header */}
        <div className="relative">
          <img
            src="https://via.placeholder.com/800x200"
            alt="Banner"
            className="w-full h-48 object-cover rounded-xl"
          />
          <div className="absolute bottom-4 left-4">
            <h2 className="text-3xl font-bold text-white">[Person's Name]</h2>
          </div>
        </div>

        {/* About Section */}
        <div className="mt-6 mb-8">
          <h3 className="text-xl font-bold text-gray-800 flex items-center gap-2">
            <FaUser className="text-blue-500" /> About
          </h3>
          <p className="text-gray-600 mt-4 leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod
            malesuada. Suspendisse potenti. Curabitur nec ante nec mi porta
            tristique.
          </p>
        </div>

        {/* Photo Gallery Section */}
        <div>
          <h3 className="text-xl font-bold text-gray-800 flex items-center gap-2">
            <FaImages className="text-purple-500" /> Gallery
          </h3>
          <div className="grid grid-cols-3 gap-4 mt-4">
            {[...Array(10)].map((_, index) => (
              <img
                key={index}
                src={`https://via.placeholder.com/150?text=Image+${index + 1}`}
                alt={`Gallery ${index + 1}`}
                className="w-full h-32 object-cover rounded-lg shadow-md cursor-pointer transition-transform duration-300 hover:scale-110"
                onClick={() =>
                  openModal(
                    `https://via.placeholder.com/150?text=Image+${index + 1}`
                  )
                }
              />
            ))}
          </div>
        </div>
      </div>

      {/* Modal for Image Preview */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4 max-w-lg w-full">
            <img
              src={modalImage}
              alt="Modal Content"
              className="w-full h-auto rounded-lg"
            />
            <button
              onClick={closeModal}
              className="mt-4 w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-all"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopularProfile;
