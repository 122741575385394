import React from "react";
import {
  FaMapMarkerAlt,
  FaImages,
  FaWhatsapp,
  FaPhoneAlt,
} from "react-icons/fa";

const MapLocationDemo = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-teal-400 via-blue-500 to-purple-600 ">
      <div className="bg-white shadow-2xl rounded-2xl p-8 w-full max-w-2xl transform transition-transform hover:scale-105 duration-300 ease-in-out">
        <h2 className="text-4xl font-extrabold text-gray-800 mb-6 text-center drop-shadow-lg">
          Jai Traders, Palwal
        </h2>
        <p className="text-gray-600 mb-8 text-center text-base leading-relaxed">
          View location details, navigate with ease, and explore photos of the
          area.
        </p>

        <div className="space-y-6">
          {/* Address Section */}
          <div>
            <h3 className="text-xl font-bold text-gray-800 flex items-center gap-2">
              <FaMapMarkerAlt className="text-blue-500" /> Address
            </h3>
            <p className="text-gray-600 mt-2">
              123 Alalpur Chawk Street, Palwal City, Haryana State, 121102
            </p>
          </div>

          {/* Map Section */}
          <div>
            <h3 className="text-xl font-bold text-gray-800 flex items-center gap-2">
              <FaMapMarkerAlt className="text-green-500" /> Map Location
            </h3>
            <iframe
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.8354345093793!2d144.9630578153167!3d-37.81421797975156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5772b292b7a29b!2s123+Example+St%2C+Example+City%2C+Example+State!5e0!3m2!1sen!2s!4v1613999479014!5m2!1sen!2s"
              width="100%"
              height="300"
              className="rounded-lg shadow-md"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>

          {/* Photos Section */}
          <div>
            <h3 className="text-xl font-bold text-gray-800 flex items-center gap-2">
              <FaImages className="text-purple-500" /> Location Photos
            </h3>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <img
                src="https://www.shutterstock.com/image-photo/panchgani-maharashtra-india-asia-april-260nw-1437714632.jpg"
                alt="Location 1"
                className="w-full h-32 object-cover rounded-lg shadow-md"
              />
              <img
                src="https://via.placeholder.com/150"
                alt="Location 2"
                className="w-full h-32 object-cover rounded-lg shadow-md"
              />
              <img
                src="https://via.placeholder.com/150"
                alt="Location 3"
                className="w-full h-32 object-cover rounded-lg shadow-md"
              />
              <img
                src="https://via.placeholder.com/150"
                alt="Location 4"
                className="w-full h-32 object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>

        {/* Actions Section */}
        <div className="mt-8 space-y-4 text-center">
          <a
            href="https://wa.me/1234567890?text=Please%20share%20your%20current%20location."
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-green-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-600 transition-all duration-300 ease-in-out font-bold text-lg flex items-center justify-center gap-2"
          >
            <FaWhatsapp className="text-xl" /> Ask for Current Location on
            WhatsApp
          </a>
          <a
            href="tel:+1234567890"
            className="inline-block bg-blue-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-600 transition-all duration-300 ease-in-out font-bold text-lg flex items-center justify-center gap-2"
          >
            <FaPhoneAlt className="text-xl" /> Call Now
          </a>
          <a
            href="https://www.google.com/maps/dir/?api=1&destination=123+Example+Street+Example+City+Example+State"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-blue-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-600 transition-all duration-300 ease-in-out font-bold text-lg"
          >
            Navigate Here
          </a>
        </div>
      </div>
    </div>
  );
};

export default MapLocationDemo;
