import React from "react";
import { FaCalendarAlt, FaMapMarkerAlt, FaTicketAlt } from "react-icons/fa";

const EventSpotlight = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-yellow-400 via-red-500 to-pink-600 animate-gradient-move">
      <div className="bg-white shadow-2xl rounded-xl p-6 w-full max-w-3xl transform transition-transform duration-300 ease-in-out">
        {/* Banner Photo */}
        <div className="relative mb-6">
          <img
            src="https://img.freepik.com/premium-psd/music-concert-banner-design-template_987701-3004.jpg"
            alt="Event Banner"
            className="w-full h-60 object-cover rounded-xl shadow-md"
          />
          <div className="absolute bottom-4 left-4 bg-black bg-opacity-50 text-white px-4 py-2 rounded-lg">
            <h2 className="text-3xl font-bold">[Event Name]</h2>
          </div>
        </div>

        {/* Event Details */}
        <div className="mb-8">
          <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center gap-2">
            <FaCalendarAlt className="text-red-500" /> Event Details
          </h3>
          <p className="text-gray-600 leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Join us for
            an unforgettable experience filled with fun, networking, and
            valuable insights.
          </p>
        </div>

        {/* Event Schedule */}
        <div className="mb-8">
          <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center gap-2">
            <FaCalendarAlt className="text-yellow-500" /> Schedule
          </h3>
          <ul className="text-gray-600 space-y-2">
            <li>
              <strong>10:00 AM:</strong> Registration and Welcome
            </li>
            <li>
              <strong>11:00 AM:</strong> Keynote Speech
            </li>
            <li>
              <strong>1:00 PM:</strong> Lunch Break
            </li>
            <li>
              <strong>2:00 PM:</strong> Panel Discussion
            </li>
            <li>
              <strong>4:00 PM:</strong> Networking Session
            </li>
          </ul>
        </div>

        {/* Location */}
        <div className="mb-8">
          <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center gap-2">
            <FaMapMarkerAlt className="text-green-500" /> Location
          </h3>
          <p className="text-gray-600">
            123 Event Street, Event City, Event State, 123456
          </p>
          <a
            href="https://www.google.com/maps"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2 inline-block text-blue-500 hover:underline"
          >
            View on Google Maps
          </a>
        </div>

        {/* RSVP and Ticket Booking */}
        <div className="text-center">
          <a
            href="https://example.com/rsvp"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-600 transition-all duration-300 ease-in-out text-lg font-bold inline-block mr-4"
          >
            RSVP Now
          </a>
          <a
            href="https://example.com/tickets"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-600 transition-all duration-300 ease-in-out text-lg font-bold inline-block"
          >
            Buy Tickets
          </a>
        </div>
      </div>
    </div>
  );
};

export default EventSpotlight;
