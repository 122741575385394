import React from "react";
import {
  FaPhoneAlt,
  FaWhatsapp,
  FaInstagram,
  FaFacebook,
  FaGlobe,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

const SocialAccess = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-400 via-blue-500 to-purple-600 animate-gradient-move">
      <div className="bg-white shadow-2xl rounded-2xl p-8 w-full max-w-md transform transition-transform hover:scale-110 duration-300 ease-in-out">
        {/* Cover Image */}
        <div className="mb-6 overflow-hidden rounded-xl shadow-lg">
          <img
            src="https://i.ytimg.com/vi/kcJfakHo9M0/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLBq_dBr2fp02Wgbr2uej1MfCXe9TA"
            alt="Social Access Cover"
            className="w-full h-48 object-cover scale-100 hover:scale-110 transition-transform duration-500 ease-in-out"
          />
        </div>

        <h2 className="text-4xl font-extrabold text-gray-800 mb-6 text-center drop-shadow-lg">
          Social Access
        </h2>
        <p className="text-gray-600 mb-8 text-center text-base leading-relaxed">
          Connect with us instantly through phone, WhatsApp, or explore our
          social profiles.
        </p>

        <div className="grid grid-cols-1 gap-6">
          {/* Contact Us Section */}
          <h3 className="text-lg font-bold text-gray-800">Contact Us</h3>
          <a
            href="tel:+1234567890"
            className="flex items-center gap-3 bg-blue-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaPhoneAlt className="text-xl" />
            <span>Call Us</span>
          </a>
          <a
            href="https://wa.me/1234567890"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-3 bg-green-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-green-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaWhatsapp className="text-xl" />
            <span>WhatsApp Us</span>
          </a>

          {/* Follow Us Section */}
          <h3 className="text-lg font-bold text-gray-800">Follow Us</h3>
          <a
            href="https://instagram.com/example"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-3 bg-pink-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-pink-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaInstagram className="text-xl" />
            <span>Instagram</span>
          </a>
          <a
            href="https://facebook.com/example"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-3 bg-blue-700 text-white px-5 py-3 rounded-lg shadow-md hover:bg-blue-800 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaFacebook className="text-xl" />
            <span>Facebook</span>
          </a>
          <a
            href="https://twitter.com/example"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-3 bg-sky-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-sky-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaTwitter className="text-xl" />
            <span>Twitter</span>
          </a>
          <a
            href="https://linkedin.com/in/example"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-3 bg-blue-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaLinkedin className="text-xl" />
            <span>LinkedIn</span>
          </a>
          <a
            href="https://youtube.com/example"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-3 bg-red-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-red-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaYoutube className="text-xl" />
            <span>YouTube</span>
          </a>
          <a
            href="https://example.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-3 bg-purple-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-purple-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaGlobe className="text-xl" />
            <span>Visit Website</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialAccess;
