import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useAuth } from "../AuthContext";

const CreateBusinessNew = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [businessName, setBusinessName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [primeBusinessLocation, setPrimeBusinessLocation] = useState("");
  const [qrType, setQrType] = useState("");
  const [timePeriod, setTimePeriod] = useState(""); // Time period field
  const [numberOfBanners, setNumberOfBanners] = useState(""); // Number of banners
  const [estimatedCost, setEstimatedCost] = useState(null); // Estimated cost

  const categories = ["Beauty", "Health", "Education", "Tech"];
  const subcategories = {
    Beauty: ["Salon", "Spa", "Makeup"],
    Health: ["Clinic", "Pharmacy", "Gym"],
    Education: ["School", "Tutoring", "College"],
    Tech: ["IT Services", "Software", "Hardware"],
  };

  const qrTypes = ["Basic", "Advanced", "Premium"];
  const timePeriods = ["1 Month", "6 Months", "1 Year"];

  // QR type costs (example values in INR)
  const qrTypeCosts = {
    Basic: 50,
    Advanced: 100,
    Premium: 150,
  };

  // Time period multipliers (example values)
  const timePeriodMultipliers = {
    "1 Month": 1,
    "6 Months": 5, // Slight discount
    "1 Year": 10, // Larger discount
  };

  const calculateEstimate = () => {
    if (!qrType || !timePeriod || !numberOfBanners || isNaN(numberOfBanners)) {
      setErrorMessage("Please fill all fields correctly.");
      return;
    }

    const qrCost = qrTypeCosts[qrType];
    const timeMultiplier = timePeriodMultipliers[timePeriod];
    const totalCost = qrCost * timeMultiplier * parseInt(numberOfBanners, 10);

    setEstimatedCost(totalCost);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!/^\+?[1-9]\d{1,14}$/.test(phoneNumber)) {
      setErrorMessage("Invalid phone number format");
      return;
    }
    setLoading(true);

    const businessData = {
      businessName,
      phoneNumber,
      email,
      category,
      subcategory,
      businessLocation,
      primeBusinessLocation:
        businessLocation === "City" ? primeBusinessLocation : "",
      qrType,
      timePeriod,
      numberOfBanners: parseInt(numberOfBanners, 10),
      estimatedCost,
      headerImage:
        "https://i0.wp.com/bcomnotes.in/wp-content/uploads/2020/05/business.png?fit=985%2C699&ssl=1",
      mainImage:
        "https://media.istockphoto.com/id/1495088043/vector/user-profile-icon-avatar-or-person-icon-profile-picture-portrait-symbol-default-portrait.jpg?s=170667a&w=0&k=20&c=LPUo_WZjbXXNnF6ok4uQr8I_Zj6WUVnH_FpREg21qaY=",
      companyName: businessName,
      natureofBusiness: subcategory,
      companyDescription: "Welcome to our business!",
      userId: user?.uid || "anonymous",
      dateCreated: serverTimestamp(),
      dateModified: serverTimestamp(),
    };

    try {
      const docRef = await addDoc(
        collection(db, "businessCardsDraft"),
        businessData
      );
      navigate(`/view/${docRef.id}`);
    } catch (error) {
      console.error("Error adding business card:", error);
      setErrorMessage("Failed to create business card. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Create Business Card</h1>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <form onSubmit={handleSubmit}>
        <label className="block mb-2">
          <span className="text-gray-700">Business Name</span>
          <input
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </label>
        <label className="block mb-2">
          <span className="text-gray-700">Phone Number</span>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </label>
        <label className="block mb-2">
          <span className="text-gray-700">Email</span>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </label>
        <label className="block mb-2">
          <span className="text-gray-700">QR Type</span>
          <select
            value={qrType}
            onChange={(e) => setQrType(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          >
            <option value="">Select a QR Type</option>
            {qrTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </label>
        <label className="block mb-2">
          <span className="text-gray-700">Time Period</span>
          <select
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          >
            <option value="">Select a Time Period</option>
            {timePeriods.map((period) => (
              <option key={period} value={period}>
                {period}
              </option>
            ))}
          </select>
        </label>
        <label className="block mb-2">
          <span className="text-gray-700">Number of Banners</span>
          <input
            type="number"
            value={numberOfBanners}
            onChange={(e) => setNumberOfBanners(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            placeholder="Enter number of banners"
            required
          />
        </label>
        <button
          type="button"
          onClick={calculateEstimate}
          className="w-full px-4 py-2 mt-4 bg-blue-500 text-white rounded"
        >
          Calculate Estimate
        </button>
        {estimatedCost !== null && (
          <div className="mt-4 p-4 bg-green-100 rounded">
            <p className="text-green-700">
              Estimated Cost: <strong>₹{estimatedCost}</strong>
            </p>
          </div>
        )}
        <button
          type="submit"
          className="w-full px-4 py-2 mt-4 bg-blue-500 text-white rounded"
          disabled={loading}
        >
          {loading ? "Saving..." : "Create Business Card"}
        </button>
      </form>
    </div>
  );
};

export default CreateBusinessNew;
