import React, { useEffect, useState, useRef } from "react";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FaLeaf } from "react-icons/fa"; // For Veg icon
import { GiChickenLeg } from "react-icons/gi"; // For Non-Veg icon
import { MdLocationOn } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ItemCard from "./ItemCard";
import menu from "./MenuItems";

const MenuWithSavedItems = ({ menuFromProps, restaurantName }) => {
  const [savedItems, setSavedItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const { restaurantId } = useParams();
  const navigate = useNavigate();
  if (menuFromProps) menu = menuFromProps;
  const categoryRefs = useRef({}); // Object to hold references for categories

  useEffect(() => {
    const saved =
      JSON.parse(localStorage.getItem(`ResturentSavedItems-${restaurantId}`)) ||
      [];
    setSavedItems(saved);
  }, [restaurantId]);

  const handleSaveItem = (item) => {
    const itemIndex = savedItems.findIndex((saved) => saved.id === item.id);
    const updatedItems =
      itemIndex !== -1
        ? savedItems.filter((saved) => saved.id !== item.id)
        : [...savedItems, { ...item }];
    setSavedItems(updatedItems);
    localStorage.setItem(
      `ResturentSavedItems-${restaurantId}`,
      JSON.stringify(updatedItems)
    );
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    if (category === "All") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const ref = categoryRefs.current[category];
      if (ref) {
        ref.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      setTimeout(() => {
        window.scrollBy({ top: -100, behavior: "smooth" });
      }, 1000);
    }
  };

  return (
    <div className="p-4">
      <header className="text-center mb-4">
        <h1 className="text-2xl font-bold text-teal-600">{restaurantName}</h1>
        <p className="text-gray-600 text-sm">Explore our delicious menu</p>
        <div className="flex justify-center items-center gap-6 mt-4">
          <button
            onClick={() => navigate(`/restaurant/${restaurantId}/location`)}
            className="flex items-center gap-2 text-teal-600 hover:text-teal-800 font-medium"
          >
            <MdLocationOn size={24} /> Share Location
          </button>
        </div>
        {/* Payment Methods Section */}
        <div className="mt-4">
          {/* <h2 className="text-lg font-semibold text-teal-600">
            Payment Methods
          </h2> */}
          <div className="flex justify-center items-center gap-4 mt-2">
            <a
              href="phonepe://pay?pa=7404121102@ybl&pn=RestaurantName&cu=INR"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center gap-1"
            >
              <img
                src={require("../assets/logos/phonepe.png")}
                alt="PhonePe"
                className="w-20 h-20"
              />
              <p className="text-sm text-gray-600">PhonePe</p>
            </a>
            <a
              href="upi://pay?pa=7404121102@icici&pn=RestaurantName&cu=INR"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center gap-1"
            >
              <img
                src={require("../assets/logos/paytm.png")}
                alt="Paytm"
                className="w-20 h-20"
              />
              <p className="text-sm text-gray-600">Paytm</p>
            </a>
            <a
              href="upi://pay?pa=gpay-id@okaxis&pn=RestaurantName&cu=INR"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center gap-1"
            >
              <img
                src={require("../assets/logos/googlepay.png")}
                alt="GPay"
                className="w-20 h-20"
              />
              <p className="text-sm text-gray-600">GPay</p>
            </a>

            <a
              href="upi://pay?pa=amazon-id@apl&pn=RestaurantName&cu=INR"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center gap-1 shadow-lg"
            >
              <img
                src={require("../assets/logos/qr-1.png")}
                alt="View QR Code"
                className="w-20 h-20"
              />
              <p className="text-sm text-gray-600">View QR Code</p>
            </a>
          </div>
        </div>
      </header>

      <div className="flex overflow-x-auto gap-4 pb-4 mb-6 border-b border-gray-300 sticky top-0 pt-6 bg-white">
        <button
          onClick={() => handleCategoryClick("All")}
          className={`px-6 py-2 rounded-full ${
            selectedCategory === "All"
              ? "bg-teal-600 text-white shadow-lg"
              : "bg-gray-200 text-gray-700"
          } whitespace-nowrap font-medium`}
        >
          All
        </button>
        {menu.map((category, index) => (
          <button
            key={index}
            onClick={() => handleCategoryClick(category.category)}
            className={`px-6 py-2 rounded-full ${
              selectedCategory === category.category
                ? "bg-teal-600 text-white shadow-lg"
                : "bg-gray-200 text-gray-700"
            } whitespace-nowrap font-medium`}
          >
            {category.category}
          </button>
        ))}
      </div>

      <div>
        {menu.map((cat) => (
          <div
            key={cat.category}
            ref={(el) => (categoryRefs.current[cat.category] = el)}
            className="mb-8"
          >
            <h2 className="text-xl font-bold text-teal-600 mb-4">
              {cat.category}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {cat.items.map((item, index) => (
                <ItemCard
                  item={item}
                  index={index}
                  handleSaveItem={handleSaveItem}
                  savedItems={savedItems}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {savedItems.length > 0 && (
        <footer className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-200 p-4 flex justify-between items-center">
          <p className="text-gray-700">
            {savedItems.length} item{savedItems.length !== 1 && "s"} saved
          </p>
          <button
            onClick={() => navigate(`/menu/${restaurantId}/Saved`)}
            className="flex items-center gap-2 bg-teal-600 text-white px-4 py-2 rounded-lg"
          >
            <AiOutlineShoppingCart size={20} />
            View Saved Items
          </button>
        </footer>
      )}
    </div>
  );
};

export default MenuWithSavedItems;
