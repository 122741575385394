import React, { useEffect, useState } from "react";

const CostCalculator = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [qrType, setQrType] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [numberOfBanners, setNumberOfBanners] = useState("");
  const [estimatedCost, setEstimatedCost] = useState(null);

  const qrTypes = [
    "Basic(Quick Phone and Whatsapp)",
    "Two Location",
    "Person Popularity",
    "Quick Links",
    "Full Profile",
  ];
  const timePeriods = ["1 Month", "3 Month", "6 Months", "1 Year"];

  // QR type costs (example values in INR)
  const qrTypeCosts = {
    "Basic(Quick Phone and Whatsapp)": 100,
    Advanced: 200,
    Premium: 150,
    "Full Profile": 200,
    "Map Location and Photo": 100,
    "Person Popularity": 200,
    "Quick Links": 150,
  };

  // Time period multipliers (example values)
  const timePeriodMultipliers = {
    "1 Month": 1,
    "3 Month": 2.5,
    "6 Months": 4, // Slight discount
    "1 Year": 8, // Larger discount
  };

  const calculateEstimate = () => {
    if (!qrType || !timePeriod || !numberOfBanners || isNaN(numberOfBanners)) {
      setErrorMessage("Please fill all fields correctly.");
      return;
    }

    const qrCost = qrTypeCosts[qrType];
    const timeMultiplier = timePeriodMultipliers[timePeriod];
    let totalCost =
      qrCost * timeMultiplier * parseInt(numberOfBanners, 10) * 0.2;
    console.log({ totalCost, qrCost });
    if (totalCost < qrCost * timeMultiplier)
      totalCost = qrCost * timeMultiplier;
    setEstimatedCost(totalCost);
    setErrorMessage(""); // Clear any previous error messages
  };
  useEffect(() => {
    calculateEstimate();
  });
  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Cost Calculator</h1>
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          calculateEstimate();
        }}
      >
        <label className="block mb-2">
          <span className="text-gray-700">QR Type</span>
          <select
            value={qrType}
            onChange={(e) => setQrType(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          >
            <option value="">Select a QR Type</option>
            {qrTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </label>

        <label className="block mb-2">
          <span className="text-gray-700">Time Period</span>
          <select
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          >
            <option value="">Select a Time Period</option>
            {timePeriods.map((period) => (
              <option key={period} value={period}>
                {period}
              </option>
            ))}
          </select>
        </label>

        <label className="block mb-2">
          <span className="text-gray-700">Number of Banners</span>
          <input
            type="number"
            value={numberOfBanners}
            onChange={(e) => setNumberOfBanners(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            placeholder="Enter number of banners"
            required
          />
        </label>

        {/* <button
          type="submit"
          className="w-full px-4 py-2 mt-4 bg-blue-500 text-white rounded"
        >
          Calculate Estimate
        </button> */}
      </form>

      {estimatedCost !== null && (
        <div className="mt-4 p-4 bg-green-100 rounded">
          <p className="text-green-700">
            Estimated Cost: <strong>₹{estimatedCost}</strong>
          </p>
        </div>
      )}
    </div>
  );
};

export default CostCalculator;
