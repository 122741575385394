import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaLeaf } from "react-icons/fa"; // For Veg icon
import { GiChickenLeg } from "react-icons/gi"; // For Non-Veg icon
import ItemCard from "./ItemCard";

const MenuSaved = () => {
  const { restaurantId } = useParams();
  const navigate = useNavigate();
  const [savedItems, setSavedItems] = useState([]);
  const [lastSavedDate, setLastSavedDate] = useState(null);

  useEffect(() => {
    const saved =
      JSON.parse(localStorage.getItem(`ResturentSavedItems-${restaurantId}`)) ||
      [];
    setSavedItems(saved);

    if (saved.length > 0) {
      const lastSaved = new Date(
        Math.max(...saved.map((item) => new Date(item.savedAt).getTime()))
      );
      setLastSavedDate(lastSaved);
    }
  }, [restaurantId]);
  const handleSaveItem = (item) => {
    const itemIndex = savedItems.findIndex((saved) => saved.id === item.id);
    const updatedItems =
      itemIndex !== -1
        ? savedItems.filter((saved) => saved.id !== item.id)
        : [...savedItems, { ...item }];
    setSavedItems(updatedItems);
    localStorage.setItem(
      `ResturentSavedItems-${restaurantId}`,
      JSON.stringify(updatedItems)
    );
  };
  return (
    <div className="p-4">
      {/* Header */}
      <header className="text-center mb-4">
        <h1 className="text-2xl font-bold text-teal-600">Saved Items</h1>
        {lastSavedDate && (
          <p className="text-gray-600 mt-2">
            Last Saved Date: {lastSavedDate.toLocaleString()}
          </p>
        )}
        <p className="text-gray-600">Restaurant ID: {restaurantId}</p>
      </header>

      {/* Saved Items */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {savedItems.length > 0 ? (
          savedItems.map((item, index) => (
            <ItemCard
              item={item}
              index={index}
              handleSaveItem={handleSaveItem}
              savedItems={savedItems}
            />
          ))
        ) : (
          <p className="text-gray-600 text-center">
            No items saved yet. Go back to the menu to save items.
          </p>
        )}
      </div>

      {/* Footer */}
      <footer className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-200 p-4 flex justify-between">
        <button
          onClick={() => navigate(`/menu/${restaurantId}`)}
          className="bg-teal-600 text-white px-4 py-2 rounded-lg"
        >
          Back to Menu
        </button>
      </footer>
    </div>
  );
};

export default MenuSaved;
