import React from "react";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";

const BasicPlan = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500 animate-gradient-move">
      <div className="bg-white shadow-2xl rounded-2xl p-8 w-full max-w-md transform transition-transform hover:scale-110 duration-300 ease-in-out">
        {/* Cover Image */}
        <div className="mb-6 overflow-hidden rounded-xl shadow-lg">
          <img
            src="https://i.ytimg.com/vi/kcJfakHo9M0/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLBq_dBr2fp02Wgbr2uej1MfCXe9TA"
            alt="Business Cover"
            className="w-full h-48 object-cover scale-100 hover:scale-110 transition-transform duration-500 ease-in-out"
          />
        </div>

        <h2 className="text-4xl font-extrabold text-gray-800 mb-6 text-center drop-shadow-lg">
          Welcome to <span className="text-red-600">Unicron Apps Design</span>
        </h2>
        <p className="text-gray-600 mb-8 text-center text-base leading-relaxed">
          Experience exceptional support! Reach out to instantly via phone or
          WhatsApp for all your inquiries.
        </p>

        <div className="flex flex-col gap-6">
          {/* First Phone Contact */}
          <a
            href="tel:+918448804428"
            className="flex items-center gap-4 bg-blue-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaPhoneAlt className="text-2xl" />
            <span>Call Now (8448804428)</span>
          </a>

          {/* Second Phone Contact */}
          <a
            href="tel:+918448804428"
            className="flex items-center gap-4 bg-blue-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaPhoneAlt className="text-2xl" />
            <span>Call Now (7404121102)</span>
          </a>

          {/* First WhatsApp Contact */}
          <a
            href="https://wa.me/8448804428"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-4 bg-green-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-green-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaWhatsapp className="text-2xl" />
            <span>WhatsApp (8448804428)</span>
          </a>

          {/* Second WhatsApp Contact */}
          <a
            href="https://wa.me/8448804428"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-4 bg-green-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-green-600 hover:shadow-lg transition-all duration-300 ease-in-out text-lg justify-center animate-fade-in"
          >
            <FaWhatsapp className="text-2xl" />
            <span>WhatsApp (8448804428)</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BasicPlan;
