import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FaLeaf } from "react-icons/fa";
import { GiChickenLeg } from "react-icons/gi";

const ItemCard = ({ item, index, handleSaveItem, savedItems }) => {
  console.log(savedItems);
  const isSaved = savedItems.find((val) => {
    console.log(val);
    console.log(val.name, item.name, val.id, item.id);
    return val.name === item.name && val.id === item.id;
  });
  console.log({ isSaved });
  return (
    <>
      <div
        key={item.id}
        className="bg-white shadow-md rounded-lg p-4 grid gap-4 items-start grid-cols-12"
      >
        {/* Item Image */}
        <div className="flex flex-col col-span-4">
          <img
            src={item.image}
            alt={item.name}
            className="w-full h-auto rounded-lg object-cover"
          />
          {item.type === "Veg" ? (
            <div className="flex items-center justify-center gap-2 text-green-600">
              <FaLeaf />
              <span className="text-xs font-semibold">Veg</span>
            </div>
          ) : (
            <div className="flex items-center justify-center gap-2 text-red-600">
              <GiChickenLeg />
              <span className="text-xs font-semibold">Non-Veg</span>
            </div>
          )}
        </div>
        {/* Item Details */}
        <div className="col-span-8">
          <div className="flex justify-between">
            <h3 className="text-lg font-semibold">{item.name}</h3>
            <button
              onClick={() => handleSaveItem(item)}
              className="text-teal-600 hover:text-teal-800"
            >
              {isSaved ? (
                <AiFillHeart size={24} />
              ) : (
                <AiOutlineHeart size={24} />
              )}
            </button>
          </div>
          <p className="text-sm text-gray-600 mt-2">{item.description}</p>
          <div className="mt-4">
            <div className="flex gap-6 flex-wrap">
              {Object.entries(item.price).map(([key, value]) => {
                if (!value) return;
                return (
                  <div key={key} className="flex flex-col">
                    <span className="text-sm text-gray-600 capitalize">
                      {key}
                    </span>
                    <span className="text-lg font-bold text-teal-600">
                      ₹{value.toFixed(2)}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-4">
            {item.available ? (
              <span className="text-green-600 font-medium">Available</span>
            ) : (
              <span className="text-red-600 font-medium">Unavailable</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemCard;
