const menu = [
  {
    category: "Momos",
    items: [
      {
        id: 1,
        name: "Veg Momos",
        price: { full: 60 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/4c0/3d53aefeef347f3d0b7b31d6f87924c0.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 2,
        name: "Paneer Momos",
        price: { full: 80 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/bb9/9f49471d9adc3418ef43d85e5032abb9.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 3,
        name: "Fried Veg Momos",
        price: { full: 70 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/038/348e16c23e2d360484c9c7465df04038.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 4,
        name: "Fried Paneer Momos",
        price: { full: 90 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/41a/744360d3364ea3ebf631dd2fc7ccc41a.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 5,
        name: "Crunchy Veg Momos",
        price: { small: 70, large: 120 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d9d/be4147b9e5f5a0ab5b9eee3a989cfd9d.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 6,
        name: "Crunchy Paneer Momos",
        price: { small: 80, large: 140 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/03e/40964c7cde5a1aa1054b3823d462403e.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Rolls",
    items: [
      {
        id: 7,
        name: "Spring Rolls",
        price: { small: 40, large: 70 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/076/aaa947246cd551264c35b0dc5bd06076.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 8,
        name: "Crunchy Spring Rolls",
        price: { small: 60, large: 110 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/076/aaa947246cd551264c35b0dc5bd06076.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Noodles",
    items: [
      {
        id: 9,
        name: "Hakka Noodles",
        price: { small: 70, large: 120 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c9d/dd8d9a5ecc189e874f58acfe08fdbc9d.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 10,
        name: "Chilli Garlic Noodles",
        price: { small: 80, large: 130 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/223/324a005229ab5cd75565b3b8deb2f223.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 11,
        name: "Singapuri Noodles",
        price: { small: 90, large: 150 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/3c6/f3955ed31543dc83acb090e9054603c6.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Veg Chinese Hunger",
    items: [
      {
        id: 12,
        name: "Dry Manchurian Balls",
        price: { full: 90 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/b38/cfa5610851063a090a489d646fce6b38.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 13,
        name: "Gravy Manchurian Balls",
        price: { full: 110 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/a3e/b1dc076ee55e3b7c59cb741268193a3e.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 14,
        name: "Chilli Potato",
        price: { full: 90 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/64d/ec7654d59c352a21aec4524298b2164d.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 15,
        name: "Sesame Honey Chilli Potato",
        price: { full: 90 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/629/33184c9098dd2e29885e13b9173c9629.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 16,
        name: "Fried Rice",
        price: { full: 80 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/766/982e66bf9e027d8068901187d1493766.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 17,
        name: "Paneer Fried Rice",
        price: { full: 100 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/766/982e66bf9e027d8068901187d1493766.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 18,
        name: "Crispy Corn Salt & Pepper",
        price: { full: 150 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d4d/b657401e04b1cb33e18fa85cae02ad4d.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 19,
        name: "Chilli Garlic Mushroom",
        price: { full: 150 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/507/f6d9987afa8babba2613bdab029e6507.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 20,
        name: "Chilli Paneer",
        price: { full: 160 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/2b6/835a1d094c2077f9249622f2bca072b6.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 21,
        name: "Chilli Soya Chap",
        price: { full: 160 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/44b/ccdd5d1a214460950bfb73d04207b44b.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Burger",
    items: [
      {
        id: 1,
        name: "Aloo Tikki Classic Burger",
        price: { full: 59 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/695/247a5230bd85ab2bc6018e62d9e38695.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 2,
        name: "Tandoori Paneer Burger",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/fb2/b9cd3a00b2ea09856020f94de6010fb2.png?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 3,
        name: "Thousand Island Veggie Burger",
        price: { full: 69 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d3b/16e889fe15572d7cc3e049e970e45d3b.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 4,
        name: "Veggie Delight Burger",
        price: { full: 69 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 5,
        name: "Spicy Salsa Burger",
        price: { full: 69 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/fb2/b9cd3a00b2ea09856020f94de6010fb2.png?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 6,
        name: "Makhni Paneer Burger",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d3b/16e889fe15572d7cc3e049e970e45d3b.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 7,
        name: "Mexican Burger",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 8,
        name: "Tangy Paneer Burger",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d3b/16e889fe15572d7cc3e049e970e45d3b.jpg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Sandwich",
    items: [
      {
        id: 9,
        name: "Veg Grill",
        price: { full: 59 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1c1/772023f6341fa1dff53cf4ae9ce241c1.png?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 10,
        name: "Corn & Cheese",
        price: { full: 69 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1c1/772023f6341fa1dff53cf4ae9ce241c1.png?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 11,
        name: "Club Sandwich",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c91/46377d5561dfb259a025c8ef1d282c91.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 12,
        name: "Tandoori Paneer",
        price: { full: 89 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c91/46377d5561dfb259a025c8ef1d282c91.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 13,
        name: "Diamond Delight",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/c91/46377d5561dfb259a025c8ef1d282c91.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 14,
        name: "Royal Spicy",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/1c1/772023f6341fa1dff53cf4ae9ce241c1.png?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Pizza",
    items: [
      {
        id: 15,
        name: "Margherita Pizza",
        price: { "6inch": 59, "8inch": 89 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 16,
        name: "Onion Pizza",
        price: { "6inch": 69, "8inch": 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 17,
        name: "Garden Fresh",
        price: { "6inch": 79, "8inch": 109 },
        available: true,
        type: "Veg",
        description: "Onion, Capsicum",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 18,
        name: "Golden Veg",
        price: { "6inch": 89, "8inch": 119 },
        available: true,
        type: "Veg",
        description: "Onion, Capsicum, Corn",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 19,
        name: "Paneer Delight",
        price: { "6inch": 99, "8inch": 139 },
        available: true,
        type: "Veg",
        description: "Onion, Capsicum, Paneer",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d3b/16e889fe15572d7cc3e049e970e45d3b.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 20,
        name: "Spicy Affair",
        price: { "6inch": 99, "8inch": 139 },
        available: true,
        type: "Veg",
        description: "Onion, Black Olives, Jalapeno, Red Paprika",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 21,
        name: "Veggie Fiesta",
        price: { "6inch": 99, "8inch": 139 },
        available: true,
        type: "Veg",
        description: "Onion, Capsicum, Corn, Mushroom",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 22,
        name: "Makhni Paneer",
        price: { "6inch": 99, "8inch": 139 },
        available: true,
        type: "Veg",
        description: "Onion, Capsicum with Makhani Sauce",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d3b/16e889fe15572d7cc3e049e970e45d3b.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 23,
        name: "Signature Pizza",
        price: { "6inch": 99, "8inch": 139 },
        available: true,
        type: "Veg",
        description: "Onion, Corn, Paneer, Red Paprika with Jalapeno Sauce",
        image:
          "https://b.zmtcdn.com/data/dish_photos/d3b/16e889fe15572d7cc3e049e970e45d3b.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 24,
        name: "Mexican Pizza",
        price: { "6inch": 99, "8inch": 139 },
        available: true,
        type: "Veg",
        description: "Onion, Capsicum, Olives, Jalapeno",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 25,
        name: "Exotic Pizza",
        price: { "6inch": 99, "8inch": 139 },
        available: true,
        type: "Veg",
        description: "Onion, Capsicum, Olives, Jalapeno",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 26,
        name: "Veg Overload",
        price: { "6inch": 99, "8inch": 139 },
        available: true,
        type: "Veg",
        description: "All Veggies",
        image:
          "https://b.zmtcdn.com/data/dish_photos/371/413ba6045cf339465d9e0ea35f5a3371.jpg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Mocktails",
    items: [
      {
        id: 1,
        name: "Virgin Mint Mojito",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/695/247a5230bd85ab2bc6018e62d9e38695.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 2,
        name: "Green Apple Cooler",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/695/247a5230bd85ab2bc6018e62d9e38695.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 3,
        name: "Chatpata Amrud (Chilli Guava)",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/695/247a5230bd85ab2bc6018e62d9e38695.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 4,
        name: "Blue Lagoon",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/695/247a5230bd85ab2bc6018e62d9e38695.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 5,
        name: "Spicy Lemonade",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/695/247a5230bd85ab2bc6018e62d9e38695.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 6,
        name: "Kala Khatta",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/695/247a5230bd85ab2bc6018e62d9e38695.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 7,
        name: "Spicy Mango",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/695/247a5230bd85ab2bc6018e62d9e38695.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 8,
        name: "Pulse Candy Cooler",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/695/247a5230bd85ab2bc6018e62d9e38695.jpg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Coffee & Tea",
    items: [
      {
        id: 9,
        name: "Cold Coffee",
        price: { full: 69 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/37e/dd2d6f7b3b67307f666064d25394737e.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 10,
        name: "Hazelnut Frappe",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/37e/dd2d6f7b3b67307f666064d25394737e.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 11,
        name: "Caramel Frappe",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/37e/dd2d6f7b3b67307f666064d25394737e.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 12,
        name: "Lemon Ice Tea",
        price: { full: 69 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/37e/dd2d6f7b3b67307f666064d25394737e.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 13,
        name: "Peach Ice Tea",
        price: { full: 69 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/37e/dd2d6f7b3b67307f666064d25394737e.jpg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Shakes",
    items: [
      {
        id: 14,
        name: "Bourbon Chocolate Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 15,
        name: "Kit-Kat Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 16,
        name: "Oreo Chocolate Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 17,
        name: "Strawberry Bourbon Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 18,
        name: "Strawberry Cheese Cake Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 19,
        name: "Blueberry Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 20,
        name: "Blueberry Cheese Cake Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 21,
        name: "Mango Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 22,
        name: "Mango Cheese Cake Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 23,
        name: "Bubblegum Cheese Cake Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/f58/c6bfb54d4a843ce35e4e941232219f58.jpg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 24,
        name: "Butterscotch Shake",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/a03/5398eec4ab7b1aeed05fec22d7eafa03.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Sides",
    items: [
      {
        id: 25,
        name: "Peri-Peri Fries",
        price: { full: 59 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/072/2b411c7938355090b148d34553c4f072.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 26,
        name: "Cheesy Fries",
        price: { full: 79 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/072/2b411c7938355090b148d34553c4f072.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 27,
        name: "Veggie Nuggets",
        price: { full: 129 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/072/2b411c7938355090b148d34553c4f072.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 28,
        name: "Cheese & Corn Nuggets",
        price: { full: 129 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/072/2b411c7938355090b148d34553c4f072.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 29,
        name: "Choco Lava Cake",
        price: { full: 59 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/0bb/53e8073b131ff4dafddee72d6792a0bb.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
  {
    category: "Pasta",
    items: [
      {
        id: 30,
        name: "Red Sauce Pasta",
        price: { full: 99 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/072/2b411c7938355090b148d34553c4f072.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 31,
        name: "White Sauce Pasta",
        price: { full: 119 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/072/2b411c7938355090b148d34553c4f072.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
      {
        id: 32,
        name: "Pink Sauce Pasta",
        price: { full: 119 },
        available: true,
        type: "Veg",
        image:
          "https://b.zmtcdn.com/data/dish_photos/072/2b411c7938355090b148d34553c4f072.jpeg?fit=around|130:130&crop=130:130;*,*",
      },
    ],
  },
];
export default menu;
